import React, {useEffect} from 'react'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {hookToAttributeSelector} from '@wix/panda-js-utils'
import {Modal} from '../modal'
import c from '../../classnames.scss'
import {isHollowButton} from '../../../selectors/settings'
import {Spinner} from '../../spinner'
import {TicketsDownloadProps} from './interfaces'
import {Ticket} from './ticket'
import {DownloadPdfButton} from './download-pdf-button'
import {Divider} from './divider'
import s from './tickets-download.scss'

export const TicketsDownload: React.FC<TicketsDownloadProps> = ({
  t,
  i18n,
  event,
  orderNumber,
  buttonStyle,
  tickets,
  ticketsPdf,
  staticsBaseUrl,
  getOrder,
  downloadTickets,
}) => {
  useEffect(() => {
    getOrder(event.id, orderNumber)
  }, [])

  const buttonClass = isHollowButton(buttonStyle) ? c.hollowActionColor : c.fullActionColor

  const downloadPdf = () => {
    window.open(ticketsPdf)
    downloadTickets()
  }

  const renderTickets = () =>
    tickets.map((ticket, index) => (
      <Ticket
        key={ticket.ticketDefinitionId}
        t={t}
        ticket={ticket}
        language={i18n.language}
        staticsBaseUrl={staticsBaseUrl}
        showBottomDivider={index + 1 < tickets.length}
        onDownload={() => downloadTickets(true)}
      />
    ))

  return (
    <Modal
      dataHook={DH.TICKETS_DOWNLOAD_MODAL}
      lastChildSelector={`[data-hook='${DH.TICKETS_DOWNLOAD_MODAL}'] > a`}
      firstChildSelector={hookToAttributeSelector(DH.CLOSE_BUTTON)}
      returnFocusElement={hookToAttributeSelector(DH.DOWNLOAD_TICKETS)}
    >
      <div className={s.title}>{t('downloadModal_download_title')}</div>
      <DownloadPdfButton t={t} className={buttonClass} onClick={downloadPdf} />
      <Divider full />
      {tickets.length ? (
        <>
          <div className={classNames(s.title, s.addToWalletTitle)}>{t('downloadModal_addToWallet_title')}</div>
          {renderTickets()}
        </>
      ) : (
        <div className={s.spinnerContainer}>
          <Spinner />
        </div>
      )}
    </Modal>
  )
}
